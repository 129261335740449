/* src/components/Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.logo img {
  height:  1.8rem;
}

.tagline-name {
  color: #000;
  font-family: "widescreen", sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
}

.tagline-title {
  color:#000;
  display: block;
  font-family: "widescreen", sans-serif;
  font-weight: 300;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  line-height: 0.8rem;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.nav-item {
  font-family: "widescreen", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: small;
}

.navbar-toggler {
  border: none;
}

.dropdown-toggle {
  color: #178C92;
  font-weight: 400;
  font-size: x-small;
}

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.menu li {
  margin-right: 20px;
}

.menu li a {
  color: #fff;
  text-decoration: none;
  font-size: 1em;
}

.menu li a:hover {
  text-decoration: underline;
}

.dropdown {
  position: relative;
  display: inline-block;
  padding-top: 3px;
}

.dropbtn {
  background-color: #333;
  color: white;
  border: none;
  font-size: 1em;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 10px;
  text-decoration: none;
  display: block;
  font-size: 0.9em;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #555;
}
