.social-section {
    padding: 10vh 0;
    background-color: #fff;
  }
  
  .stay-connected {
    margin-bottom: 5vh;
  }

  .stay-connected h2 {
    font-family: "widescreen", sans-serif;
    font-weight: 600;
    font-size: 2rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  .stay-connected p {
    font-family: "widescreen-ex", sans-serif;
    font-weight: 400;
    font-size: medium;
    line-height: 2em;
  }
  
  .lottie-container {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    padding-bottom: 8px;
  }
  
  .spinning-wheel {
    width: 35px; 
    display: inline-block;
  }
  
  .instagram-feed {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .instagram-card {
    background-color: #fff;
    max-width: 400px;
    border: 1px solid #707070;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 30px;
  }

  .instagram-card-link {
    text-decoration: none;
    color: inherit;
    display: block;
  }
  
  .card-header {
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 25px;
    border-bottom: 1px solid #707070;
    font-family: "widescreen-ex", sans-serif;
    font-weight: 400;
  }
  
  .profile-pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .card-image img {
    width: 100%;
    display: block;
  }
  
  .card-footer {
    padding: 15px 25px 15px 25px;
    border-top: 1px solid #707070;
  }
  
.icon {
    height: 22px;
}

.icons-left .icon {
    margin-right: 10px;
}