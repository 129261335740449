/* General Section Styling */
section {
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;
}

/* Hero Section Styling */
.hero-section {
  min-height: 100vh;
  background-color: #fff; /* Adjust as needed */
}

.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 0 3% 3% 3%; 
}


/* Mobile Styling */
@media (max-width: 768px) {
  section .hero-paragraph {
    font-size: 2vh;
    max-width: 80%;
  }

  section .heading-left,
  section .heading-right {
  font-size: 10vw;
  line-height: 1.5em;
  margin: 0;
  white-space: nowrap;
  }
  .hero-section {
    height: 100vh;
  }

  .hero-content {
    height: 85vh;
    justify-content: space-evenly;
  }

  .hero-footer {
    flex-direction: column-reverse;
    gap: 20px
  }
}

/* Headings Styling */
.heading-container {
  position: relative;
  width: 100%;
}

.heading-left,
.heading-right {
  font-family: "widescreen-ex", sans-serif;
  font-weight: 800;
  font-size: 8vw;
  line-height: 1em;
  text-transform: uppercase;
  margin: 0;
  white-space: nowrap;
}

.heading-left {
  text-align: left;
}

.heading-right {
  text-align: right;
  margin-top: 20px; /* Add spacing between headings */
}

/* Hero Paragraph Styling */
.hero-paragraph {
  font-family: "widescreen-ex", sans-serif;
  font-weight: 400;
  font-size: 1.3vh;
  line-height: 1.5;
  max-width: 20%;
  text-align: left;
  margin: 0 auto;
}

/* Hero Footer (CTA and Social Links) */
.hero-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5%;
}

.social-links {
  display: flex;
  gap: 20px;
}

.cta-button {
  background: linear-gradient(90deg, #F05F6E, #8169AE);
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 30px;
  font-family: "widescreen-ex", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 8px 20px rgba(12, 102, 135, 0.2);
  text-transform: uppercase;
  text-decoration: none;
}

.cta-button .arrow-icon {
  transition: transform 0.3s ease;
  transform-origin: center;
  transform: rotate(-90deg);
}

.cta-button:hover {
  transform: translateY(-3px);
}

.cta-button:hover .arrow-icon {
  transform: rotate(0deg);
}

/* Social Links Styling */
.social-links a {
  color: #6B6B6B;
  text-decoration: none;
  font-family: "widescreen", sans-serif;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: color 0.3s ease;
}

.social-links a .arrow-icon {
  transition: transform 0.3s ease;
  transform-origin: center;
  transform: rotate(-135deg);
}

.social-links a:hover {
  color: #3b3b3b;
}

.social-links a:hover .arrow-icon {
  transform: rotate(-90deg);
}

/* Second Viewport Section Styling */
.second-viewport {
  padding: 100px 20px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.profile-image {
  width: 100%;
  margin-bottom: 20px;
}

.text-content h2 {
  font-family: "widescreen", sans-serif;
  font-weight: 600;
  font-size: 2rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.text-content p {
  font-family: "widescreen-ex", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.who-i-am {
  font-size: 0.9rem;
}

.lottie-heading-container {
  display: flex;
  align-items: center;
  height: auto;
}
