html {
  overflow-y: scroll;
  min-height: 100vh;
}
body {
  overflow-y: hidden;
  overflow-x: hidden;
}

html, body {
  margin: 0;
  padding: 0;
}

.gsap-container {
  min-width: 220%;
  height: 100vh;
  display: flex;
  background-color: #fff;
  position: relative;
}


.gradient-line {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: 4px; 
  background: linear-gradient(to right, #F05F6E, #8169AE, #178C92); 
  z-index: 0; 
}  

.firstContainer {
  flex-direction: column;
  background: #fff;
  padding: 10vh 0;
  margin: 0;
}

.my-services {
  display: flex;
  justify-content: center;
  height: 100%;
}

.my-services h2 {
  font-family: "widescreen", sans-serif;
  font-weight: 600;
  font-size: 2rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.my-services p {
  font-family: "widescreen-ex", sans-serif;
  font-weight: 400;
  font-size: medium;
  line-height: 2em;
}

.panel {
  width: 850px;
  height: 100vh;
  flex-shrink: 0;
  padding-top: 50px;
  padding-left: 2%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.panel h4 {
  position: relative;
  font-family: "widescreen", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  z-index: 1;
  text-transform: uppercase;
}

.p-service p {
    font-family: "widescreen-ex", sans-serif;
    font-weight: 400;
    font-size: small;
    line-height: 1.8;
    margin-bottom: 20px;
    width: 60%;
  }

.panel img {
  max-width: 80%;
  height: auto;
  margin-top: 5%;
  padding-left: 10%;
  position: absolute;
  top: calc(40%);
}

.gradient-line {
  top: calc(50px + 0.8rem);
}

/* Mobile Styling */
@media (max-width: 768px) {
  .panel {
    width: 550px;
  }

  .panel img {
    max-width: 60%;
    padding-left: 5%;
    position: absolute;
    top: calc(55%);
  }
}

