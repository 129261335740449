/* Custom styling for the contact section */
.contact-section {
    padding: 10vh 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    min-height: 100vh;
  }
  
  .contact-card {
    background: linear-gradient(180deg, #F05F6E, #8169AE);
    border: none;
    border-radius: 40px;
    padding: 30px;
    color: white;
    max-width: 1200px;
    width: 100%;
    box-shadow: 0 8px 20px rgba(90, 12, 135, 0.2);
    transition: all 0.3s ease;
  }
  
  .h2-contact {
    color:#fff;
    text-transform: uppercase;
    font-family: "widescreen-ex", sans-serif;
    font-weight: 800;
    margin-bottom: 2vh;
  }

  .contact-card h3 {
    font-family: "widescreen", sans-serif;
    font-weight: 600;
    font-size: x-large;
    color:#fff;
    margin-bottom: 20px;
  }
  
  .contact-card p {
    font-family: "widescreen-ex", sans-serif;
    font-weight: 400;    
  }

  .contact-alt {
    color:#fff;
    line-height: 0.5rem;
    font-size: small;
    font-family: "widescreen", sans-serif;
    font-weight: 600 !important;
    margin-top: 20px;
  }
  
  .thank-you-message h2 {
    font-family: "widescreen", sans-serif;
    font-weight: 600;
    font-size: 2rem;
    text-transform: uppercase;
  }
  
  .thank-you-message p {
    margin-top: 20px;
    font-size: 1.2rem;
  }
  
  /* When the form is submitted, shrink the card */
  .contact-card.submitted {
    padding: 50px;
    text-align: center;
  }
  
  .contact-card.submitted .row {
    display: none;
  }
  
  /* Responsive styling for stacking columns on mobile */
  @media (max-width: 768px) {
    .contact-card .row {
      flex-direction: column;
    }
  }

  /* Contact form Submit button */
  .contact-btn {
    background-color: #178C92;
    color: white;
    padding: 10px 20px 10px 10px;
    border: none;
    border-radius: 30px;
    font-family: "widescreen-ex", sans-serif;
    font-weight: 800;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px; /* Space between text and icon */
    transition: background-color 0.3s ease;
    box-shadow: 0 8px 20px rgba(12, 102, 135, 0.2);
  }
  
  .contact-btn .arrow-icon {
    transition: transform 0.3s ease;
    transform-origin: center;
    transform: rotate(-135deg); /* Initial orientation, pointing to the top-right corner */
  }
  
  .contact-btn:hover {
    background-color: #12A7B3;
  }

  .contact-btn:hover .arrow-icon {
    transform: rotate(-90deg); /* Rotate to horizontal pointing right */
  }

  /* Form fields */
  /*** Label ***/
  .form-label {
    font-family:"widescreen-ex", sans-serif ;
    font-weight: 400;
    font-size: small;
    margin: 0;
  }

  /*** All input fields ***/
  .form-control {
    border-radius: 15px;
    border: none;
    font-size: medium;
    margin-bottom: 5px;
    padding: 10 0;
  }

  /*** Placeholder text ***/
  ::placeholder {
    font-family: "widescreen-ex", sans-serif;
    color: #969696 !important;
  }

  /*** Error message ***/
  .text-danger {
    font-size: small;
    font-family: "widescreen", sans-serif;
    font-weight: 500;
  }