.privacy-policy-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    flex: 1;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.privacy-policy-content h1 {
    font-family: "widescreen", sans-serif;
    font-weight: 600;
    font-size: x-large;
    text-transform: uppercase;
}

.privacy-policy-content h2 {
    font-family: "widescreen", sans-serif;
    font-weight: 600;
    font-size: large;
    text-transform: uppercase;
    margin-top: 30px;
}

.privacy-policy-content h3 {
    font-family: "widescreen", sans-serif;
    font-weight: 600;
    font-size: small;
}

.privacy-policy-content h4 {
    font-family: "widescreen", sans-serif;
    font-weight: 400;
    font-size: x-small;
}

.privacy-policy-content p {
    font-family: "widescreen", sans-serif;
    font-weight: 400;
    font-size: small;
    line-height: 2em;
}

.privacy-policy-content li {
    font-family: "widescreen", sans-serif;
    font-weight: 400;
    font-size: small;
    line-height: 2em;
}

.privacy-policy-content a {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    text-decoration: none;
    color: #707070;
}


