.footer {
    background-color: #178C92 !important;
    color: white;
    padding: 20px 0;
  }
  
  .footer h5 {
    font-family: "widescreen", sans-serif;
    font-weight: 500;
    font-size: medium;
  }

  .footer h6 {
    font-family: "widescreen", sans-serif;
    font-weight: 300;
    font-size: small;
    line-height: 1.5;
  }
  
  .footer p {
    font-family: "widescreen", sans-serif;
    font-weight: 300;
    font-size: x-small;
    color: #bbb;
    margin-top: 1.5rem;
  }
  
.footer-social-links {
    display:flex;
    gap: 20px;
    font-family: "widescreen", sans-serif;
    margin-top: 1rem;
}

.footer-social-links a {
    color: white;
    font-size: medium;
    align-items: center;
    display: flex;
    gap: 5px;
    transition: transform 0.3s ease;
}

.footer-social-links a .arrow-icon {
    transition: transform 0.3s ease;
    transform-origin: center;
    transform: rotate(-135deg); /* Initial orientation, pointing to the top-right corner */
}


.footer-social-links a:hover .arrow-icon{
    transform: rotate(-90deg); 

}

  .footer a {
    text-decoration: none;
  }
  
  .footer a:hover {
    color: #fff;
  }
  
  .footer ul {
    padding-left: 0;
    list-style: none;
  }

  .btn-custom {
    color: #fff;
    background-color: #ffffff00;
    border: none;
    font-family: "widescreen", sans-serif;
    font-weight: 300;
    font-size: medium;
    transition: background-color 0.3s ease;
  }

  /* Info Container */

  .info-container {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap; /* Allows content to wrap on smaller screens */
  }
  
  .privacy-link {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: inherit;
    font-size: x-small;
    font-family: inherit;
    margin-left: 50px;
    padding-bottom: 6px;
  }

  .privacy-link svg {
    margin-right: 5px; /* Space between the icon and text */
    width: 10px;
    height: 10px;
  }

  .info-container a .arrow-icon {
    transition: transform 0.3s ease;
    transform-origin: center;
    transform: rotate(-135deg); /* Initial orientation, pointing to the top-right corner */
    
  }

  .info-container a:hover .arrow-icon{
      transform: rotate(-90deg); 

  }
  
  @media (max-width: 960px) { /* Adjust the width to control wrapping */
    .privacy-link {
      margin-left: 0px;
      margin-top: 8px; /* Add some space between the h6 and link when stacked */
    }
    
    .info-container {
      justify-content: flex-start; /* Align content to the start on smaller screens */
    }
  }
  