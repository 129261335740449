/* src/styles/NotFound.css */
.not-found-container {
    text-align: center;
    padding: 50px;
    min-height: 85vh;
  }
  
  .not-found-container h1 {
    font-size: 100px;
    margin-bottom: 20px;
  }
  
  .not-found-container p {
    font-size: 20px;
    margin-bottom: 30px;
  }
  
  .home-link {
    font-size: 18px;
    text-decoration: none;
    color: #007bff;
  }
  
  .home-link:hover {
    text-decoration: underline;
  }
  